<div id="header">
  <img decoding="async" width="453" height="78" src="https://crediflow.es/wp-content/uploads/2024/02/logo.png" alt="" title="" srcset="https://crediflow.es/wp-content/uploads/2024/02/logo.png 453w, https://crediflow.es/wp-content/uploads/2024/02/logo-300x52.png 300w" sizes="(max-width: 453px) 100vw, 453px" class="wp-image-179" />
</div>
<div id="main-section">
  <div class="container">
   


<ng-content></ng-content>


</div>
</div>

<div id="links">
  <a href="https://crediflow.es/contacto/">¿Tienes dudas? ¡A qué esperas... Hablemos!</a>
  <a role="button" (click)="openTyC()">Información importante del préstamo...</a>
  <a role="button" (click)="openFooter()" class="last">Links de interés...</a>
</div>

<div id="tyc" *ngIf="showTyC">

      
      
      <div class="container">
      <div class="et_pb_text_inner"><p>Prestamista: AGILCRED FINANCIAL, S.L, sociedad de nacionalidad española, domiciliada en Calle Valentin sanz, 23 – PISO 2, Santa cruz de Tenerife, 38002, Tenerife, con Número de Identificación Fiscal (N.I.F.) B56505050. Su actividad no está sometida a la regulación y supervisión del Banco de España.</p>
<p>Términos y condiciones aplicables: El solicitante ha de ser una persona física, mayor de edad y con domicilio permanente en España. El solicitante podrá escoger el importe y el plazo deseado a través del simulador en el sitio web o su área de cliente y visualizar el importe total a pagar, así como tambien el detalle de intereses a pagar y TAE. Todas las solicitudes de préstamo están sujetas a aprobación del Prestamista.</p>
<p><strong>Se pueden solicitar de 200 a 400 Euros en un plazo de 7 a 45 días.</strong></p>
<p><strong>Las tasas varían según el plazo seleccionado:<br></strong></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>De 7 a 15 días:&nbsp; TIN: 677% , TEM: 55,64%, TNA: 21657,30%.</li>
<li>De 16 a 45 días TIN 402%, TEM: 33,04%, TNA: 3124,74%.</li>
</ul>
</li>
</ul>
<p>Por ejemplo:</p>
<ul>
<li style="list-style-type: none;">
<ul>
<li style="padding-bottom: 15px;">Si solicitas un préstamo de 400€ en un plazo de 45 días, la TIN será de 402%, la TAE de 3124,74%, el interés será de 198€ y el importe total a devolver será de 598€.</li>
<li>Si solicitas un préstamo de 400€ en un plazo de 10 días, la TIN será de 677%, la TAE de 21657,30%, el interés será de 74 € y el importe total a devolver será de 474€.</li>
</ul>
</li>
</ul>

<p><strong>Si eres un ganador del sorteo la tasa es 0:<br></strong></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>De 7 a 15 días:&nbsp; TIN: 0% , TEM: 0%, TNA: 0%.</li>
<li>De 16 a 45 días TIN 0%, TEM: 0%, TNA: 0%.</li>
</ul>
</li>
</ul>
<p>Por ejemplo:</p>
<ul>
<li style="list-style-type: none;">
<ul>
<li style="padding-bottom: 15px;">Si solicitas un préstamo de 400€ en un plazo de 45 días, la TIN será de 0%, la TAE de 0%, el interés será de 0€ y el importe total a devolver será de 400€.</li>
<li>Si solicitas un préstamo de 400€ en un plazo de 10 días, la TIN será de 0%, la TAE de 0%, el interés será de 0 € y el importe total a devolver será de 400€.</li>
</ul>
</li>
</ul>


<p><strong>En caso de impago, la penalización por mora será:</strong></p>
<ul>
<li style="list-style-type: none;">
<ul>
<li>Si el plazo seleccionado es desde 1 a 15 días: 1,85% diario sobre el principal impagado.</li>
<li>Si el plazo seleccionado es desde 16 a 45 días: a 1,10% diario sobre el principal impagado.</li>
</ul>
</li>
</ul>
<p>Por ejemplo:</p>
<ul>
<li style="list-style-type: none;">
<ul>
<li style="padding-bottom: 15px;">Si solicitas un préstamo de 400€ en un plazo de 45 días, la TIN será de 402%, la TAE de 3124,74%, el interés será de 198€ y el importe total a devolver será de 598€ en el plazo de 45 días naturales desde la fecha de solicitud. Si te demoras 7 días naturales en pagar, desde la fecha de vencimiento, el interés moratorio será de 47,63€ y el importe total a devolver será de 646,08€.</li>
<li>Si solicitas un préstamo de 400€ en un plazo de 10 días, la TIN será de 677%, la TAE de 21657,30%, el interés normal será de 74€ y el importe total a devolver será de 474€ en el plazo de 10 días desde la fecha de solicitud. Si te demoras 7 días naturales en pagar, desde la fecha de vencimiento, el interés moratorio será de 64,92€ y el importe total a devolver será de 539,12€.</li>
</ul>
</li>
</ul>
      
    </div>

    <app-button (clicked)="closeTyC()"  >Cerrar</app-button>
</div>

</div>


<footer id="main-footer" class="clearfix" *ngIf="showLinks">
      
<div class="container">
<div id="footer-widgets" class="clearfix">
  <div class="footer-widget"><div id="block-7" class="fwidget et_pb_widget widget_block"><div>
      <h5>¿Necesitas ayuda?</h5>
      <ul class="list-unstyled">
        <li class=""><a href="https://wa.me/34670909086?text=%C2%A1Hola!%20Quiero%20hablar%20con%20un%20asesor." target="_blank">Hablemos por Whatsapp.</a></li>
        <li class=""><a href="https://crediflow.es/#como-funciona">Cómo funciona.</a></li>
        <li class=""><a href="https://crediflow.es/#faq">Preguntas frecuentes.</a></li>
      </ul>
      <img decoding="async" src="https://crediflow.es/wp-content/uploads/2024/01/logo.png" alt="Crediflow.es ¡Tu microcrédito al instante!" id="logo-footer" class="img- /logo">
    </div></div></div><div class="footer-widget"><div id="block-8" class="fwidget et_pb_widget widget_block"><div>
      <h5>Empresa</h5>
      <ul class="list-unstyled">
        <li class=""><a href="https://prestamo.crediflow.es/">Simulador de préstamos.</a></li>
        <li class=""><a href="https://clientes.crediflow.es/" target="_blank">Acceso miembros.</a></li>
        <li class=""><a href="https://crediflow.es/denuncias">Canal de denuncias.</a></li>
<li class=""><a href="https://crediflow.es/formulario-de-desistimiento">Formulario de desistimiento.</a></li>
        <li class=""><a href="https://crediflow.es/contacto">Contacto.</a></li>
      </ul>
    </div></div></div><div class="footer-widget"><div id="block-9" class="fwidget et_pb_widget widget_block"><div>
      <h5>Términos y condiciones</h5>
      <ul class="list-unstyled">
<li class=""><a href="https://crediflow.es/terminos-y-condiciones">Términos y condiciones.</a></li>
        <li class=""><a href="https://crediflow.es/politica-de-privacidad">Política de privacidad.</a></li>
        <li class=""><a href="https://crediflow.es/politica-de-cookies">Política de cookies.</a></li>
        
      </ul>
    </div></div></div><div class="footer-widget"><div id="block-10" class="fwidget et_pb_widget widget_block"><div class="footer-section">
      <h5>Síguenos</h5>
      <ul class="list-unstyled">
        <li class="red"><a href="https://www.facebook.com/crediflow.es" target="_blank" rel="noopener"><img decoding="async" src="https://crediflow.es/wp-content/uploads/2024/01/fb.png" border="0" class="img-red"></a></li>
        <li class="red"><a href="https://www.instagram.com/crediflow.es/" target="_blank" rel="noopener"><img decoding="async" src="https://crediflow.es/wp-content/uploads/2024/01/ig.png" border="0" class="img-red"></a></li>
      </ul>
    </div></div></div>	</div>
</div>


  
      <div id="footer-bottom">
        <div class="container clearfix">
                </div>
      </div>

      <app-button (clicked)="closeFooter()" >Cerrar</app-button>

    </footer>
